<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/grupos">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/grupos"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="grupos-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                    <div class="">
                        <div class="field field-checkbox">
                            <label for="ativo" class="mr-2 ml-0">Ativo</label>
                            <InputSwitch id="ativo" v-model="form.ativo" />
                        </div>
                        <div class="field">
                            <label for="name">Descrição</label>
                            <InputText
                                id="descricao"
                                v-model.trim="form.descricao"
                                required="true"
                                autofocus
                                autocomplete="off"
                                :class="{ 'p-invalid': submitted && !form.descricao }"
                            />
                            <small class="p-error" v-if="submitted && !form.descricao">Descrição é obrigatório.</small>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import AppInfoManual from '../../components/AppInfoManual.vue';
import BaseService from '../../services/BaseService';
import { getCurrentCompany } from '@/services/store';

export default {
    data() {
        return {
            form: {
                ativo: true
            },
            submitted: false,
            customers: []
        };
    },
    created() {
        this.service = new BaseService('/grupos');
        this.$serviceCustomer = new BaseService('/customers/combo');
    },
    async mounted() {
        this.loadCustomers();
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar grupo' : 'Adicionar grupo';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        }
    },
    watch: {
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        }
    },
    methods: {
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave() {
            this.submitted = false;
        },
        async onLoadDataEdit(data) {
            this.form = data;
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }
            return cbSuccess();
        },
        hasError() {
            if (!this.form.descricao)
                return true;
        },
        async loadCustomers() {
            const { data } = await this.$serviceCustomer.findAll({});
            this.customers = data;
        }
    },
    components: { AppInfoManual }
};
</script>